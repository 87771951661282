/* eslint-disable react/prop-types */
import React from "react";
import "../css/projects.css";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import { AnalyticsGA } from "../utils/Analytics";

const Projects = (props) => {
  const { projects } = props;
  return (
    <>
      <div className="projects-container container-lg">
        {projects.map((proj) => (
          <ProjectCard
            title={proj.title}
            description={proj.description}
            skills={proj.skills}
            links={proj.links}
            image={proj.image}
            key={proj.title}
          />
        ))}
      </div>
    </>
  );
};

const ProjectCard = (props) => {
  const {
    title, description, skills, links,
  } = props;
  return (
    <>
      <div className="project-card">
        {/*   <div className="image-container">
          <img className="project-image" src={image} alt="project" />
        </div> */}
        <div className="project-background" />
        <div className="project-content">
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          <ProjectSkills skills={skills} />
          <ProjectLinks links={links} />

        </div>
      </div>
    </>
  );
};

const ProjectSkills = (props) => {
  const { skills } = props;
  return (
    <>
      <div className="project-skills">
        {skills.map((skill, index) => <ProjectSkill skill={skill} key={index} />)}
      </div>
    </>
  );
};

const ProjectSkill = (props) => {
  const { skill } = props;
  return (
    <>
      <span className="project-skill">{skill}</span>
    </>
  );
};

const ProjectLinks = (props) => {
  const { links: { github, preview } } = props;
  return (
    <div className="project-links">
      <a
        onClick={(e) => AnalyticsGA(e)}
        href={`//${preview}`}
        rel=" noopener noreferrer"
        target="_blank"
        className="project-link"
      >
        Live Demo
        <FaExternalLinkAlt />

      </a>
      <a
        onClick={(e) => AnalyticsGA(e)}
        href={github}
        rel=" noopener noreferrer"
        target="_blank"
        className="project-link secondary"
      >
        View Source
        <FaGithub />
      </a>
    </div>
  );
};

export default Projects;
