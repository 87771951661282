import React from "react";
import "../css/contact.css";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

const Contact = () => (
  <>
    <div className="contact-section" id="contact">
      <a
        href="https://www.linkedin.com/in/can-mert"
        rel="noopener noreferrer"
        target="_blank"
      >
        <h3 style={{ marginRight: "40px", color: "blue" }}>
          Linkedin
          {" "}

          <FaLinkedinIn />

        </h3>
      </a>
      <a
        href="https://github.com/caanmert"
        rel="noopener noreferrer"
        target="_blank"
      >
        <h3 style={{ color: "black" }}>
          Github
          <FaGithub />
        </h3>
      </a>
    </div>
  </>
);

export default Contact;
