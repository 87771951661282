export default [
  {
    input: "Can.currentLocation",
    return: "Varna,Bulgaria",
  },
  {
    input: "Can.contactInfo",
    return:
      "[\"<a href=\"mailto:canmert.dev@gmail.com\">canmert.dev@gmail.com</a>\", \"<a rel=\"noopener\" href=\"https://www.linkedin.com/in/can-mert\">LinkedIn</a>\", \"<a rel=\"noopener\" href=\"https://github.com/caanmert\">github</a>\"]",
  },
  {
    input: "Can.resume",
    return:
      "\"<a rel=noopener noreferrer\" href=\"resume/canmert_resume.pdf\" download target=\"_blank\">canmert.pdf</a>\"",
  },
  {
    input: "Can.interests",
    return: "[\"football\", \"formula 1\", \"cooking\", \"gaming\"]",
  },
  {
    input: "Can.education",
    return:
      "\"Technical University of Varna\"",
  },
  {
    input: "Can.skills",
    return:
      "[\"React\", \"React Native\", \"Redux\", \"NodeJS\", \"Express\", \"MongoDB\", \"Java\", \"Spring\", \"git\"]",
  },

];
