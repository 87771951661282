import ReactGA from "react-ga";

const LinkedinGA = (event) => {
  event.preventDefault();
  ReactGA.event({
    category: "Links",
    action: "linkedinClick",
    label: "linkedin",
  });
};

const ResumeGA = (event) => {
  event.preventDefault();
  ReactGA.event({
    category: "Links",
    action: "ResumeClick",
    label: "resume",
  });
};
const GitGA = (event) => {
  event.preventDefault();
  ReactGA.event({
    category: "Links",
    action: "GithubClick",
    label: "github",
  });
};

const ProjectGA = (event) => {
  console.log(event.target.href);
  event.preventDefault();
  ReactGA.pageview(event.target.href);
  /*  ReactGA.event({
    category: "Links",
    action: "ProjectClick",
    label: "project",
  }); */
  window.location = event.target.href;
};

const AnalyticsGA = (event) => {
  console.log(event.target.href);
  event.preventDefault();
  ReactGA.pageview(event.target.href);
  window.location = event.target.href;
};

export {
  LinkedinGA, ResumeGA, ProjectGA, GitGA, AnalyticsGA,
};
