import React, { useEffect } from "react";
import ReactGA from "react-ga";
import NavigationBar from "./components/NavigationBar";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import About from "./components/About";
import "./css/App.css";
import Terminal from "./components/Terminal";
import statements from "./contents/terminal";
import projects from "./contents/projects";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("UA-217419889-1");
    ReactGA.pageview("/");
  }, []);

  return (
    <>
      <NavigationBar />
      <About />
      <Terminal statements={statements} />
      <h2 id="projects" className="section-title">
        Projects
      </h2>
      <Projects projects={projects} />
      <h2 id="contact" className="section-title">
        Contact
      </h2>
      <Contact />

    </>
  );
};
export default App;
